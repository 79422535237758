.dropdown:hover .dropdown-menu {
  display: block;
}

@media only screen and (max-width: 480px) {



  .chart {
    display: none;
  }
}

@media only screen and (min-width: 480px) {
  .mobileheader {
    display: none;

  }
}

li>ul {
  transform: translatex(100%) scale(0)
}

li:hover>ul {
  transform: translatex(-100%) scale(1)
}

li>button svg {
  transform: rotate(-90deg)
}

li:hover>button svg {
  transform: rotate(-270deg)
}

/* Below styles fake what can be achieved with the tailwind config
   you need to add the group-hover variant to scale and define your custom
   min width style.
   See https://codesandbox.io/s/tailwindcss-multilevel-dropdown-y91j7?file=/index.html
   for implementation with config file
*/
.group:hover .group-hover\:scale-100 {
  transform: scale(1)
}

.group:hover .group-hover\:-rotate-180 {
  transform: rotate(180deg)
}

.scale-0 {
  transform: scale(0)
}

.min-w-32 {
  min-width: 8rem
}


@media only screen and (min-width: 480px) {
  .logo__icon {
    background-image: url("../../assets/indexText.svg");
    font-size: 0;
    margin-right: 5px;
    background-repeat: no-repeat;
    width: 100px;
    height: 26px;
    background-size: contain;
  }

  .logo__icon:hover {
    background-image: url("../../assets/indexTextHover.svg");
    background-repeat: no-repeat;
    width: 100px;
    height: 26px;
    background-size: contain;
    margin-right: 5px;
  }
}

@media only screen and (min-width: 480px) {
  .user__icon {
    background-image: url("../../assets/icons.png");
    font-size: 0;
    margin-right: 5px;
    background-repeat: no-repeat;
    width: 100px;
    height: 26px;
    background-size: contain;
  }

  .user__icon:hover {
    background-image: url("../../assets/icons.png");
    background-repeat: no-repeat;
    width: 100px;
    opacity: 0.6;
    height: 26px;
    background-size: contain;
    margin-right: 5px;
  }
}